import { FormProvider, useForm } from '@graphcommerce/ecommerce-ui'
import { Trans } from '@lingui/react'
import { useState } from 'react'
import IconUser from '../icons/IconUser'
import IconUserAdd from '../icons/IconUserAdd'
import CodeForm from './CodeForm'
import LoginForm from './LoginForm'
import SendCodeForm from './SendCodeForm'
import SignupForm from './Signup'

export enum FormType {
  login = 'login',
  signup = 'signup',
  code = 'code',
  sendcode = 'sendcode',
}

type AuthFormsProps = {
  activeForm?: FormType
  stayAtPage?: boolean
  hideTabs?: boolean
  showLinks?: boolean
}

// eslint-disable-next-line import/no-default-export
export default function AuthForms({
  activeForm: activeFormProp = FormType.login,
  stayAtPage,
  hideTabs = false,
  showLinks = false,
}: AuthFormsProps) {
  const [activeForm, setActiveForm] = useState(activeFormProp)
  const [activeTab, setActiveTab] = useState(activeFormProp)
  const [data, setData] = useState({})
  const methods = useForm()

  const handleLoginClick = () => {
    if (activeForm === FormType.signup) {
      setActiveForm(FormType.login)
      setActiveTab(FormType.login)
    }
  }

  const handleSignupClick = () => {
    if (activeForm === FormType.login || activeForm === FormType.code) {
      setActiveForm(FormType.signup)
      setActiveTab(FormType.signup)
    }
  }

  const loginButtonClass = `flex justify-center w-[10rem] h-12 items-center ${activeTab === FormType.login ? 'bg-secondary-300 text-white' : 'bg-light-170'
    } rounded-s-sm`
  const signupButtonClass = `flex justify-center w-[10rem] h-12 items-center ${activeTab !== FormType.login ? 'bg-secondary-300 text-white' : 'bg-light-170'
    } rounded-e-sm`

  return (
    <div className='relative min-h-[10rem]'>
      {!hideTabs && (
        <div className='absolute md:top-[-40px] right-0 left-0 flex justify-center'>
          <button type='button' className={loginButtonClass} onClick={handleLoginClick}>
            <IconUser className='ml-2' width={14} height={14} />
            <span className='text-sm'>
              <Trans id='Sign in' />
            </span>
          </button>

          <button type='button' className={signupButtonClass} onClick={handleSignupClick}>
            <IconUserAdd className='ml-2' width={14} height={14} />
            <span className='text-sm'>
              <Trans id='Register' />
            </span>
          </button>
        </div>
      )}
      <FormProvider {...methods}>
        {activeForm === FormType.login && (
          <LoginForm showLinks={showLinks} setActiveForm={setActiveForm} stayAtPage={stayAtPage} />
        )}
        {activeForm === FormType.signup && (
          <SignupForm showLinks={showLinks} setActiveForm={setActiveForm} setData={setData} />
        )}
        {activeForm === FormType.code && (
          <CodeForm setActiveForm={setActiveForm} setData={setData} />
        )}
        {activeForm === FormType.sendcode && (
          <SendCodeForm setActiveForm={setActiveForm} data={data} stayAtPage={stayAtPage} />
        )}
      </FormProvider>
    </div>
  )
}
