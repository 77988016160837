import { useCustomerQuery } from '@graphcommerce/magento-customer'
import { useEffect, useState } from 'react'
import { customerDocument, customerQuery } from '../graphql/login/currentCustomerData.gql'

export default function useCustomerData() {
  const [customerData, setCustomerData] = useState<customerQuery['customer']>(null)
  const { data, loading } = useCustomerQuery(customerDocument, { fetchPolicy: 'network-only' })

  useEffect(() => {
    if (data?.customer) setCustomerData(data.customer)
    else setCustomerData(null)
  }, [data])

  const customerIsLogin = () => {
    if (customerData) return true
    return false
  }

  return { customerData, customerIsLogin, loading }
}
